import { graphql } from 'gatsby';
import React from 'react';
import { adaptParagraphs } from '@src/adapters/paragraph';
import Paragraph from '@src/components/paragraph';
import LayoutDefault from '@src/layouts/default';
import { CgfQuery } from '@types';
import styles from './cgf.module.scss';

interface CGFProps {
  data: CgfQuery;
}

const CGFPage: React.FC<CGFProps> = ({ data }) => {
  const paragraphsData = adaptParagraphs(data.paragraphs);
  const cgfData = paragraphsData.find(e => e.id == 'cgf');

  return (
    <LayoutDefault pageId="cgf">
      <div className={styles.page}>
        {cgfData && (
          <div className={styles.cgf}>
            <div className={styles.content}>
              <Paragraph paragraph={cgfData} />
            </div>
          </div>
        )}
      </div>
    </LayoutDefault>
  );
};

export const query = graphql`
  query CGF {
    paragraphs: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "paragraph" } }
        frontmatter: { id: { in: ["cgf"] } }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            title
            centered
            image {
              childImageSharp {
                fluid(maxWidth: 330, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            image_right
          }
        }
      }
    }
  }
`;

export default CGFPage;
